<template>
  <el-dialog :visible.sync="visible" title="批量新增员工" width="fit-content">
    <el-upload
      action="#"
      :limit="limitNum"
      :drag="true"
      :auto-upload="true"
      accept=".xlsx, .xlsm, .xls"
      :before-upload="beforeUploadHandler"
      :on-exceed="exceedFile"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传xlsx文件，且不超过10M</div>
    </el-upload>
  </el-dialog>
</template>

<script>
import { read, utils } from "xlsx";

export default {
  data() {
    return {
      dataForm: [
        "type",
        "title",
        "knowledgeType",
        "A",
        "B",
        "C",
        "D",
        "solution",
      ],
      visible: false,
      limitNum: 1,
    };
  },
  methods: {
    // 组件初始化
    init() {
      this.visible = true;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${
          files.length + fileList.length
        } 个`
      );
    },
    // 上传文件之前的钩子
    beforeUploadHandler(file) {
      const _this = this;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          // 切换为新的调用方式
          const workbook = read(data, {
            type: "binary",
          });

          // 取第一张表
          const wsname = workbook.SheetNames[0];
          // 切换为新的调用方式 生成json表格内容

          const ws = utils.sheet_to_json(workbook.Sheets[wsname], {
            header: this.dataForm,
            range: 1,
          });
          // 后续为自己对ws数据的处理

          let result = ws.map((ele) => {
            let answers = "";

            if (ele.type == 2) {
              // 判断题
            } else {
              // 单选or多选
              answers = [ele["A"], ele["B"], ele["C"], ele["D"]];
              answers = answers.filter((ele2) => ele2);
              answers = answers.join("_");
            }

            return {
              type: ele.type,
              title: ele.title,
              knowledgeType: ele.knowledgeType,
              answers: answers,
              solution: ele.solution,
            };
          });

          this.$http.question
            .batchSave({
              questionList: result,
            })
            .then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1000,
                onClose: () => {
                  _this.visible = false;
                  _this.$nextTick(() => {
                    _this.$emit("refreshDataList");
                  });
                },
              });
            })
            .catch((err) => {
              console.log(err, "err");
            });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
      //
      return false;
    },
  },
};
</script>
<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    custom-class="dialog-warpper"
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
      <el-card>
        <div slot="header">题目信息</div>

        <el-descriptions border :column="1">
          <el-descriptions-item :labelStyle="{ width: '100px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>题目类型</span>
            </template>

            <el-form-item prop="type">
              <el-select
                v-model="dataForm.type"
                clearable
                style="width: 100%"
                placeholder="请选择题目类型"
                @change="typeChange"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>题目</span>
            </template>

            <el-form-item prop="title">
              <el-input v-model="dataForm.title" placeholder="题目"></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>知识类型</span>
            </template>

            <el-form-item prop="knowledgeType">
              <el-select
                v-model="dataForm.knowledgeType"
                placeholder="选择知识类型"
                clearable
              >
                <el-option
                  v-for="item in knowledgeTypeOpt"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px" v-if="dataForm.type != 2">
        <div slot="header">答案集</div>

        <!-- 单选题 -->
        <el-form-item v-if="dataForm.type != 2">
          <el-form-item class="answers" label="1.">
            <el-input
              v-model="answersArr[0]"
              placeholder="请输入第一个答案"
            ></el-input>
          </el-form-item>
          <el-form-item class="answers" label="2." style="margin-top: 8px">
            <el-input
              v-model="answersArr[1]"
              placeholder="请输入第二个答案"
            ></el-input>
          </el-form-item>
          <el-form-item class="answers" label="3." style="margin-top: 8px">
            <el-input
              v-model="answersArr[2]"
              placeholder="请输入第三个答案"
            ></el-input>
          </el-form-item>
          <el-form-item class="answers" label="4." style="margin-top: 8px">
            <el-input
              v-model="answersArr[3]"
              placeholder="请输入第四个答案"
            ></el-input>
          </el-form-item>
        </el-form-item>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header">正确答案</div>
        <el-form-item prop="solution">
          <div v-if="dataForm.type == 0">
            <el-radio v-model="dataForm.solution" label="A" border>A</el-radio>
            <el-radio v-model="dataForm.solution" label="B" border>B</el-radio>
            <el-radio v-model="dataForm.solution" label="C" border>C</el-radio>
            <el-radio v-model="dataForm.solution" label="D" border>D</el-radio>
          </div>
          <div v-if="dataForm.type == 1">
            <el-checkbox-group v-model="solutionCheckList">
              <el-checkbox label="A" border></el-checkbox>
              <el-checkbox label="B" border></el-checkbox>
              <el-checkbox label="C" border></el-checkbox>
              <el-checkbox label="D" border></el-checkbox>
            </el-checkbox-group>
          </div>
          <div v-if="dataForm.type == 2">
            <el-radio v-model="dataForm.solution" label="1" border>对</el-radio>
            <el-radio v-model="dataForm.solution" label="0" border>错</el-radio>
          </div>
        </el-form-item>
      </el-card>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// mixin混入
import { priMixin } from "@/mixins/mixins.js";

export default {
  mixins: [priMixin],
  data() {
    return {
      // 多选正确答案集数组
      solutionCheckList: [],
      // 答案集数组
      answersArr: ["", "", "", "", "", ""],
      // 题目类型
      typeOptions: [
        {
          value: 0,
          label: "单选题",
        },
        {
          value: 1,
          label: "多选题",
        },
        {
          value: 2,
          label: "判断题",
        },
      ],
      visible: false,
      dataForm: {
        id: 0,
        uid: "",
        title: "",
        type: 0,
        answers: "",
        solution: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        knowledgeType: "",
      },
      dataRule: {
        title: [{ required: true, message: "题目不能为空", trigger: "blur" }],
        type: [
          {
            required: true,
            message: "题目类型不能为空",
            trigger: "blur",
          },
        ],
        knowledgeType: [
          {
            required: true,
            message: "知识类型不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.solutionCheckList = [];
      this.answersArr = ["", "", "", ""];
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.question.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              if (this.dataForm.type == 1) {
                // 多选题
                this.solutionCheckList = this.dataForm.solution.split("_");
              }
              this.answersArr = this.dataForm.answers.split("_");
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          // 答案集
          requestDate.answers = "";
          for (let i = 0; i < this.answersArr.length; i++) {
            if (this.answersArr[i]) {
              requestDate.answers =
                requestDate.answers + this.answersArr[i] + "_";
            }
          }
          requestDate.answers = requestDate.answers.substring(
            0,
            requestDate.answers.length - 1
          );

          // 正确答案集
          if (requestDate.type == 1) {
            //多选题
            requestDate.solution = "";
            if (this.solutionCheckList.length > 0) {
              this.solutionCheckList.sort();
              for (let i = 0; i < this.solutionCheckList.length; i++) {
                if (this.solutionCheckList[i]) {
                  requestDate.solution =
                    requestDate.solution + this.solutionCheckList[i] + "_";
                }
              }
              requestDate.solution = requestDate.solution.substring(
                0,
                requestDate.solution.length - 1
              );
            }
          }

          if (this.dataForm.id) {
            // 修改
            this.$http.question.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.question.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
    // 题目类型发生变化
    typeChange() {
      this.solutionCheckList = [];
      this.answersArr = ["", "", "", ""];
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .answers .el-form-item__label {
    width: 1px !important;
  }
  .answers .el-form-item__content {
    margin-left: 18px !important;
  }
}
</style>
